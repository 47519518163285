<template>
  <div id="orderHistory">
    <!-- Table View -->
    <VueDatatable
      ref="myTable"
      :setupTable="setupTable"
      :selectBtn="selectBtn"
      @handleSelect="rejectOrders"
      :noSetupMounted="true"
      :allowRefresh="true"
    >
      <template v-slot:thead>
        <tr>
          <th class="id-col" width="59">
            <span>ID</span>
          </th>
          <th width="100px">
            <b-form-select
              v-model="search.status"
              :options="statusOptions"
              @change="(v) => dataTable.searching(v, 1)"
              size="sm"
            />
          </th>
          <th width="120px">
            <b-form-select
              v-model="search.service"
              :options="serviceOptions"
              @change="(v) => dataTable.searching(v, 2)"
              size="sm"
            />
          </th>
          <th class="not-mobile">IMEI</th>
          <th class="not-mobile">Result</th>
          <th class="not-mobile">Submited</th>
          <th class="not-mobile">Credit</th>
          <th class="none">Notes</th>
          <th>Category</th>
          <th class="not-mobile">User</th>
        </tr>
      </template>
    </VueDatatable>
  </div>
</template>

<script>
import VueDatatable from "@/components/util/vue-datatable/Index.vue";
import { setupTable, serviceOptions } from "@/datatable/order.datatable.js";
import { statusOptions } from "@/global/variables.js";
import { BFormSelect } from "bootstrap-vue";
import OrdersServerice from "@/services/orders.service.js";
export default {
  name: "OrderHistroy",
  data: () => ({
    dataTable: {},
    setupTable,
    search: {
      status: null,
      service: null,
    },
    statusOptions,
    serviceOptions: [],
    selectBtn: {
      loading: false,
      color: "danger",
      text: "Reject orders",
    },
  }),
  async mounted() {
    this.dataTable = this.$refs.myTable;
    this.serviceOptions = await serviceOptions();
    this.dataTable.setup({ url: "/api/orders/all", userColumn: true });
  },
  methods: {
    async rejectOrders(orders) {
      this.selectBtn.loading = true;
      await OrdersServerice.rejectOrders(orders.pluck("id").toArray());
      this.dataTable.refresh();
      this.selectBtn.loading = false;
    },
  },
  components: {
    VueDatatable,
    BFormSelect,
  },
};
</script>

<style>
</style>