import api from "@axios";

// FOR ORDERS
class Orders {
    // Get all Orders belongs to user
    static async fetch() {
        const { data } = await api.get('/orders');
        return data
    }
    // Add note for orders
    static async addNote(orders_id, note) {
        const { data } = await api.post('/orders/add-note', { orders_id, note });
        return data
    }
    // Reject Orders: array orders id
    static async rejectOrders(ordersId) {
        if(!confirm('Are you sure to delete ?')) return;
        await api.post('/orders/reject-orders', {orders: ordersId})
    }
}

export default Orders;